import React from 'react';
import '../styles/essays.css';

//

const AiEssay = () => {
  return (


    <div className='Essays'>
      <div className='aiessay'>




      <h2>The Winners and Losers of the LLM Revolution</h2>
      <h4 className='date'> Andy Fazliu / January 4th, 2023</h4>

      <p>
    Some of the biggest winners will be the startups that take a vertical and train an A.I. to be better than the best
    humans in the world
    in that vertical. Imagine the world's best doctors training the world's best doctor A.I. Imagine the world's best lawyers 
    training the world's best lawyer A.I., and so on. This will have a dramatic impact on market structure for those industries. It will
    drop the cost of the best service in the world in each field exponentially, compared to human counterparts which are limited by
    the number of hours in a day, and a whole host of mistakes that humans uniquely make. Why use the 100th best doctor/lawyer in the world 
    when you can use the best doctor/lawyer in the world, 24/7, for a fraction of the cost? 
    </p>
      <p>
      It is impossible to overstate how much of a game changer 'specialized A.I.s' will be. It will be the most important technological
      change since electricity, and I believe it will set the stage for the AGI singularity transition. OpenAI is not likely to 
      develop AGI on its own. The reason it can't is because the data they train on nets out to the average knowledge of the internet.
      Of course, the average knowledge of the internet is not the same as the knowledge of the best doctor, lawyer, investor, etc. It 
      will require special purpose A.I. teams to train A.I.s that are better than the best humans in the world at their respective fields, and then
      possibly combine or fuse them into a general purpose A.I. that can do all of those tasks for you. Then, you'll have an AGI that 
      meets the definition of being better than us at everything, not just some things.
      </p>

      <p>


    Throughout history until today, the world's best at a field could not satisfy the entire market due to constraints
      on their time. In contrast, when you look at Google, one of the best ads producers (enablers) in the world, they can satisfy
      the entire market. This is because they are not constrained by time. They have a self-serve ads system available to
      everyone in the world, 24/7. This is why Google is worth $1.5T and the world's best law firm is worth a tiny fraction of that. But, what happens when
      the world's best lawyer is an A.I. that can satisfy the entire market in a self-serve manner like Google does? 
      You have the most incredible change in capitalism: everyone gaining access to the world's best, at everything. A Google for every
      important service.
      </p>

      <p>

      Of course, there are some important hurdles we need to overcome before we get there. The first is the hallucination problem. 
      This is well discussed so I won't go into detail. Essentially LLMs make stuff up a lot. We need to make them more grounded in facts or 
      testable hypotheses.
       Next, is the reasoning problem - although rapid
      improvements have been made they still struggle with complex reasoning which is at the core of being the best lawyer, investor, etc.
      </p>
      <p>
      There are other issues but each does seem solvable in the coming months and years. 

      What about the losers? It is clear that the first wave of losers are any person or firm with exposure to 
      'low-knowledge' work. Such as basic graphic design, or basic programming teachers. These are the first to go. In only a few months of being released, 
      ChatGPT 
      has already destroyed the business models of some publicly traded companies such as Chegg and Udemy. Why pay for a programming
      teacher when you can get a better one for free? Why pay for a graphic designer when you can get a better one for free? Same goes
      for homework help, and basic mental health counseling, relationship coaching, career coaching, etc.
      </p>

      <p>
      The longer term losers are the ~2nd - 99th best of firms and workers in each knowledge service field. If the best of the best
      can copy their knowledge into an A.I. and then distribute it to the entire market, then the 2nd - 99th best of firms and workers
      will have the same rough time Google competitors had trying to convince customers to use the 2nd best search engine. The key 
      takeaway from this is A.I. will significantly exacerbate power laws, so its best to be the best at something, or be a platform
      that enables the best of the best to distribute their knowledge to the entire market.
    
    </p>
        </div>

        

        </div>

        

    )
}

export default AiEssay;
