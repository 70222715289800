import React from 'react';

import '../styles/landingpage.css';
import { Amplify } from "aws-amplify";

//import nobackgroundpic from '../nobackgroundpic.png';




const awsconfig = {
  "aws_project_region": "us-east-1",
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,
};

Amplify.configure(awsconfig);

const LandingPage = () => {

    return (
      <div className='full-landing'>


        <div className="landing-page">



    <p className='bio'>I'm Andy. I love thinking about foundational AI models, and the latest in AI agents. If you're building something interesting in that space, or anything you're deeply passionate about, dm me on twitter--</p>
    <p>I could help you think through a problem, or introduce you to the right investor.</p>
    <p>Beyond technology and investing, I think life is about building deep bonds with great people, and laughing often together. </p>
   

        </div>
        </div>
    );      
}

export default LandingPage;