import React from 'react';
import '../styles/essays.css'

const SolanaEssay = () => {
    return ( 
    
    
    <div className='Essays'>

<h2>
      Solana at $3B Market Cap is a Generational Opportunity
    </h2>

    <h4 className='date'>
      Andy Fazliu / December 28th, 2022 </h4>
    <p>     Important note: This should NOT be interpreted as financial advice.</p>




    <p>
    There are various temporary factors that have contributed to Solana's market cap falling nearly 97% from its recent peak. 
    One: the big story, SBF committing massive widescale fraud on the crypto ecosystem and his perceived importance to Solana.
    Two: the cyclical bear market in crypto (everything is down 80% or more, most alts are down 95% or more). Three: the Fed tightening at
    a historic pace. Four: forced selling due to
    mass liquidations.What you will notice is that none of these factors are related to Solana's fundamentals or its technology.</p>
    
    <p>
      One: SBF is not Solana. Solana is a decentralized network of 1000s of nodes around the world. SBF is a single person who believed in Solana
      and now he's gone. But there are thousands of other believers who have built entire businesses on Solana and are not going anywhere. The foundation
      itself has a multi-hundred million dollar war chest and can easily support the ecosystem for years to come. Two: There have been 6 crypto cycles 
      since Bitcoin was released. As the saying goes, "this time it's different" is the most expensive phrase in investing, because cycles will continue to keep happening,
      and its not different - this is not the end of cycles, crypto will come back up again and will go down again. Three: The Fed is combatting inflation
      and if you take a deeper look at the data, you will see that the Fed is actually behind the curve, again, as inflation is already subsiding on a month-over-month basis, yet they are still 
      tightening. This means despite their hawkish rhetoric, they are likely to reverse course in the coming months. Four: Forced selling is a temporary phenomenon that will subside as the market stabilizes.
    </p>

    <p>
      
    I've always found that during bear markets the pressure to compress your time horizon is immense. If it looks like 
    a stock or token has the potential to 
    fall another 30% next week, no one will buy and hold it, regardless of the potential long term upside. But for that very reason - since 
    everyone is thinking the same way,
    the value of doing the opposite and expanding your time horizon becomes unusually rewarding. In Solana, you have a situation where 
    a 3% movement in the price of ETH would be the equivalent of a doubling of Solana's market cap. This is despite Solana's technology being 
    at least 5 years ahead of Ethereum's -- Sol is several orders of magnitude faster, cheaper, and runs at a live production TPS usage rate that 
    exceeds all other chains. It has also organically developed a unique NFT subculture that is growing rapidly. Other competitor chains have offered millions 
    of dollars in grants to persuade Solana teams to build on their chains, but Solana has not had to do this.



    </p>

    <p>
      It is very rare to have tech that is 5 years ahead of your competitors and be worth only 3% of their market cap. Sol can 10x and 
      still arguably be cheap. What other stock or token can we say that about? If Sol was 30% of ETH's market cap, would it's valuation be really
      impressive to anyone, given it is better, faster, cheaper by >100x?
       It took an unprecedented, unforced blowup 
      of a $30B billionaire to cause such a deep discount in Solana. That kind of event does not happen often and the incredible dislocations it causes do not last long (they get arb'd away).

    </p>

    </div> 
    
    
    );
}

export default SolanaEssay;

