import React from 'react';
import '../styles/essays.css';
import { useNavigate } from 'react-router-dom';

const Predictions = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  }

  return (
    <div className='Essays'>
      <div className='aiessay'>
        <div>
          <a 
            href="/ai" 
            onClick={(e) => {
              e.preventDefault();
              handleNavigation('/ai');
            }}
          >
            <p>The Winners and Losers of the LLM Revolution, 
              <br/>January 4th, 2023</p>
          </a>
        </div>

        <div>
          <a 
            href="/sol" 
            onClick={(e) => {
              e.preventDefault();
              handleNavigation('/sol');
            }}
          >
            <p>Solana at $3B Market Cap is a Generational Opportunity,
              <br/>
            December 28th, 2022
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Predictions;
