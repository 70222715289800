import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react';
import Sidebar from './otherpages/Sidebar';

import { Amplify } from "aws-amplify";

//import MainPage from './otherpages/mainpage';
import AiEssay from './otherpages/aiessay';
import SolanaEssay from './otherpages/solanaessay';
import LandingPage from './otherpages/landingpage';
import Projects from './otherpages/projects';
import Predictions from './otherpages/predictions';

const awsconfig = {
  "aws_project_region": "us-east-1",
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,
};

Amplify.configure(awsconfig);



const MainContent = () => {
  //const [isAllowedToSpeak, setIsAllowedToSpeak] = useState(false);
  //const location = useLocation();

  return (
      <div className={`App`}>
          <Sidebar />

          <Routes className="routes-container">
              <Route exact path="/" element={<LandingPage />} />
              <Route exact path="/ai" element={<AiEssay />} />
              <Route exact path="/sol" element={<SolanaEssay />} />
              <Route exact path="/startups" element={<Projects />} />
              <Route path="/predictions" element={<Predictions />} />
          </Routes>
      </div>
  );
};






const App = () => {
  return (
      <Router>
          <MainContent />
      </Router>
  );
}

export default App;


//               <Route exact path="/chat" element={<MainPage isAllowedToSpeak={isAllowedToSpeak} />} />
//        <div className={`App ${location.pathname.replace("/", "")}`}>
