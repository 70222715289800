/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNonUniqueUserVisits = /* GraphQL */ `
  query GetNonUniqueUserVisits($id: ID!) {
    getNonUniqueUserVisits(id: $id) {
      id
      location
      convo {
        items {
          id
          location
          entirechathistory
          createdAt
          updatedAt
          nonUniqueUserVisitsConvoId
        }
        nextToken
      }
      origin
      actions {
        items {
          id
          action
          createdAt
          updatedAt
          nonUniqueUserVisitsActionsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNonUniqueUserVisits = /* GraphQL */ `
  query ListNonUniqueUserVisits(
    $filter: ModelNonUniqueUserVisitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonUniqueUserVisits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConvos = /* GraphQL */ `
  query GetConvos($id: ID!) {
    getConvos(id: $id) {
      id
      location
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      entirechathistory
      createdAt
      updatedAt
      nonUniqueUserVisitsConvoId
    }
  }
`;
export const listConvos = /* GraphQL */ `
  query ListConvos(
    $filter: ModelConvosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConvos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location
        nonuniqueuservisit {
          id
          location
          origin
          createdAt
          updatedAt
        }
        entirechathistory
        createdAt
        updatedAt
        nonUniqueUserVisitsConvoId
      }
      nextToken
    }
  }
`;
export const getActions = /* GraphQL */ `
  query GetActions($id: ID!) {
    getActions(id: $id) {
      id
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      action
      createdAt
      updatedAt
      nonUniqueUserVisitsActionsId
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nonuniqueuservisit {
          id
          location
          origin
          createdAt
          updatedAt
        }
        action
        createdAt
        updatedAt
        nonUniqueUserVisitsActionsId
      }
      nextToken
    }
  }
`;
