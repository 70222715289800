import React from 'react';
import '../styles/projects.css'


function Projects() {
  // Array of project data


  return (
    <div className="projects-container-parent">
      <p>
        I'm currently building <span className='bold'>a1a2</span>, a research acceleration tool. 
      </p>
      

      <p>
        Previously, I built:
        </p>

      
        <ul>
        <li><span className='bold'>Dachi Partners</span>, a $5m investment fund that focuses on special situations.</li>
        <li><span className='bold'>Pitch Goblin</span>, an investor social media app that tracks the performance of your ideas.</li>
        <li><span className='bold'>Lassy</span>, a global generative AI crypto game on Ethereum and Solana.</li>
        <li><span className='bold'>Tweelings</span>, an LLM-based app that assesses market sentiment by interpreting tweets.</li>
        <li><span className='bold'>Joy</span>, a social mental health app that connects people to talk about their problems.</li>
        <li><span className='bold'>Blackpaper</span>, a marketplace that enables the buying and selling of fractional equity interests in homes.</li>
        </ul>
    </div>
  );
}

export default Projects;

/*


    <div className="projects-container">
      {projects.map((project, index) => (
        <div key={index} className="project-item">
          <h3>{project.title}</h3>
          <p>{project.description}</p>
          <p>{project.date}</p>
        </div>
      ))}
    </div>

  const projects = [
    { title: 'a1a2', description: 'CEO & Founder of a specialized A.I. that is trained on the best qualities of the best investors in history.', date: '2023' },
    { title: 'Dachi Partners', description: 'CEO & Founder of a $2m investment fund that focuses on special situations.', date: '2023' },
    { title: 'Pitch Goblin', description: 'Founded and built an investor social media app that tracks the performance of your ideas.', date: '2023' },
    { title: 'Lassy', description: 'Built a global generative A.I. crypto game on Ethereum and Solana.', date: '2022' },
    { title: 'Dividend Smart Contract', description: 'Built a truly trustless dividend smart contract on Ethereum, which enabled seamless earnings distribution from an online game.', date: '2022' },
    { title: 'A.I. Roaster', description: 'Built an LLM-based app that makes fun of you based on your favorite memory in life.', date: '2021' },
    { title: 'A.I. Story Generator', description: 'Built an LLM-based app that crafts a short story for you based on your interests.', date: '2021' },
    { title: 'A.I. Market Sentiment Tweet Analyzer', description: 'Built an LLM-based app that assesses general risk appetite in markets by interpreting tweets. ', date: '2021' },
    { title: 'Joy', description: 'Built a social mental health app that connects people to talk about their problems.', date: '2020' },
    { title: 'Blackpaper', description: 'Built a marketplace that enables the buying and selling of fractional equity interests in home equity.', date: '2020' },

  ];


*/