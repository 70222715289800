/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNonUniqueUserVisits = /* GraphQL */ `
  mutation CreateNonUniqueUserVisits(
    $input: CreateNonUniqueUserVisitsInput!
    $condition: ModelNonUniqueUserVisitsConditionInput
  ) {
    createNonUniqueUserVisits(input: $input, condition: $condition) {
      id
      location
      convo {
        items {
          id
          location
          entirechathistory
          createdAt
          updatedAt
          nonUniqueUserVisitsConvoId
        }
        nextToken
      }
      origin
      actions {
        items {
          id
          action
          createdAt
          updatedAt
          nonUniqueUserVisitsActionsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNonUniqueUserVisits = /* GraphQL */ `
  mutation UpdateNonUniqueUserVisits(
    $input: UpdateNonUniqueUserVisitsInput!
    $condition: ModelNonUniqueUserVisitsConditionInput
  ) {
    updateNonUniqueUserVisits(input: $input, condition: $condition) {
      id
      location
      convo {
        items {
          id
          location
          entirechathistory
          createdAt
          updatedAt
          nonUniqueUserVisitsConvoId
        }
        nextToken
      }
      origin
      actions {
        items {
          id
          action
          createdAt
          updatedAt
          nonUniqueUserVisitsActionsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNonUniqueUserVisits = /* GraphQL */ `
  mutation DeleteNonUniqueUserVisits(
    $input: DeleteNonUniqueUserVisitsInput!
    $condition: ModelNonUniqueUserVisitsConditionInput
  ) {
    deleteNonUniqueUserVisits(input: $input, condition: $condition) {
      id
      location
      convo {
        items {
          id
          location
          entirechathistory
          createdAt
          updatedAt
          nonUniqueUserVisitsConvoId
        }
        nextToken
      }
      origin
      actions {
        items {
          id
          action
          createdAt
          updatedAt
          nonUniqueUserVisitsActionsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConvos = /* GraphQL */ `
  mutation CreateConvos(
    $input: CreateConvosInput!
    $condition: ModelConvosConditionInput
  ) {
    createConvos(input: $input, condition: $condition) {
      id
      location
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      entirechathistory
      createdAt
      updatedAt
      nonUniqueUserVisitsConvoId
    }
  }
`;
export const updateConvos = /* GraphQL */ `
  mutation UpdateConvos(
    $input: UpdateConvosInput!
    $condition: ModelConvosConditionInput
  ) {
    updateConvos(input: $input, condition: $condition) {
      id
      location
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      entirechathistory
      createdAt
      updatedAt
      nonUniqueUserVisitsConvoId
    }
  }
`;
export const deleteConvos = /* GraphQL */ `
  mutation DeleteConvos(
    $input: DeleteConvosInput!
    $condition: ModelConvosConditionInput
  ) {
    deleteConvos(input: $input, condition: $condition) {
      id
      location
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      entirechathistory
      createdAt
      updatedAt
      nonUniqueUserVisitsConvoId
    }
  }
`;
export const createActions = /* GraphQL */ `
  mutation CreateActions(
    $input: CreateActionsInput!
    $condition: ModelActionsConditionInput
  ) {
    createActions(input: $input, condition: $condition) {
      id
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      action
      createdAt
      updatedAt
      nonUniqueUserVisitsActionsId
    }
  }
`;
export const updateActions = /* GraphQL */ `
  mutation UpdateActions(
    $input: UpdateActionsInput!
    $condition: ModelActionsConditionInput
  ) {
    updateActions(input: $input, condition: $condition) {
      id
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      action
      createdAt
      updatedAt
      nonUniqueUserVisitsActionsId
    }
  }
`;
export const deleteActions = /* GraphQL */ `
  mutation DeleteActions(
    $input: DeleteActionsInput!
    $condition: ModelActionsConditionInput
  ) {
    deleteActions(input: $input, condition: $condition) {
      id
      nonuniqueuservisit {
        id
        location
        convo {
          nextToken
        }
        origin
        actions {
          nextToken
        }
        createdAt
        updatedAt
      }
      action
      createdAt
      updatedAt
      nonUniqueUserVisitsActionsId
    }
  }
`;
