
import { useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';
import React, {useEffect, useState, useRef} from 'react';
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from "../graphql/queries";
import axios from 'axios';


const awsconfig = {
  "aws_project_region": "us-east-1",
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_AWS_APPSYNC_APIKEY,
};

Amplify.configure(awsconfig);

const Sidebar = ({ setIsAllowedToSpeak }) => {
  const [isOpen, setIsOpen] = useState(false);  // Used to track expanded state of the sidebar
  const [currentPath, setCurrentPath] = useState('/'); // Default to the root path.
  const [menunameis, setMenunameis] = useState("Bio"); // Default to the root path.



  const invokeUrl = 'https://nlg3ef4y53.execute-api.us-east-1.amazonaws.com/dev/items'
  const navigate = useNavigate();
  const hasRun = useRef(false);
  const urlCheckHasRun = useRef(false); // New flag for the new useEffect


  useEffect(() => {
      if (hasRun.current) return;

      // check if path is /chat 
      const path = window.location.pathname;
      if (path === "/") {
        figureOutLocation("User viewed bio");
      } 
      hasRun.current = true;
    }, []);


    // each time current path changes set menunameis to a corresponding value

    useEffect(() => {
      if (currentPath === "/") {
        setMenunameis("Andy Fazliu");
      } else if (currentPath === "/chat") {
        setMenunameis("Talk to my AI");
      } else if (currentPath === "/startups") {
        setMenunameis("Startups");
      } else if (currentPath === "/workwithme") {
        setMenunameis("Work with me");
      } else if (currentPath === "/ai") {
        setMenunameis("LLM Prediction");
      } else if (currentPath === "/sol") {
        setMenunameis("Solana essay");
      } else {
        setMenunameis("Bio");
      }
    }, [currentPath]);




    const figureOutLocation = async (passinaction) => {
      try {
        const response = await axios.get(`https://ipapi.co/json/`);
        const responsecode = response.status;
        let cityy;
        let countryy;
        let fulllocation;
    
        if (responsecode !== 200) {
          const countryboo = "Unknown"
          const cityboo = "Unknown"
          const regionboo = "Unknown"
          
          cityy = cityboo;
          countryy = countryboo;
    
          fulllocation = cityy +  ", " + regionboo + ", " + countryy;
        } else {
          const countryboo = await response.data.country_name;
          const cityboo = await response.data.city;
          const regionboo = await response.data.region;
          cityy = cityboo;
          countryy = countryboo;
          fulllocation = cityy + ", " + regionboo + ", "+ countryy;
        }
    
        
        const timeyprefixed = new Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date());
    
        // turn the / into - for the id and turn : into - and turn spaces into - for timey 
    
        let timey;
        if (timeyprefixed) {
          timey = timeyprefixed.replace(/\//g, "-").replace(/:/g, "-").replace(/ /g, "-").replace(/,/g, "");
          console.log(timey + "timey is defined")
        } else {
          // Handle the case when timeyprefixed is undefined
          console.error('timeyprefixed is undefined');
        }
              
    
        const concatedCityAndDate = cityy + "-" + countryy +  "-" + timey;
        // create 
        const idForVisitAndyF = concatedCityAndDate;
    
        // Store idForVisit in sessionStorage
        sessionStorage.setItem('idForVisitAndyF', idForVisitAndyF);
    
    
        
    
        const params = new URLSearchParams(window.location.search);
        const originatedfrom = params.get('o');
        let originatedfromfinal;
     
         if (originatedfrom === "twb") {
           originatedfromfinal = "My twitter bio";
         } else if (originatedfrom === "ycfp") {
           originatedfromfinal = "yc founder profile page";
         } else if (originatedfrom === "yccs") {
           originatedfromfinal = "yc cofounder matching page";
         } else if (originatedfrom === "p") {
           originatedfromfinal = "Pitch";
         } else if (originatedfrom) {
           originatedfromfinal = originatedfrom;
         } else if (!originatedfrom) {
           originatedfromfinal = "other";
         }

         const usermsg = "none";
         const aimsg = "none";
         const title = passinaction;
         
          // Constructing the email header and body
          const emailHeader = `${title}`;
          const emailBody = `
          Location: ${fulllocation}
          User Message: ${usermsg}
          AI Message: ${aimsg}
          Origin: ${originatedfromfinal}
          `;

          const payload = {
              emailHeader: emailHeader,
              emailBody: emailBody
          };
 
          fetch(invokeUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });

      
          await API.graphql({
          query: mutations.createNonUniqueUserVisits,
          variables: {
            input: {
              id: idForVisitAndyF,
              location: fulllocation,
              origin: originatedfromfinal,
            }
          }
        });
        

      // rand num between 1 and 1000
      const randNum = Math.floor(Math.random() * 1000) + 1;
          
      
        await API.graphql({
          query: mutations.createActions,
          variables: {
            input: {
              id: idForVisitAndyF.substring(0,2) + "-" + timey + "-" + randNum, // Assuming this is the correct field to link with NonUniqueUserVisits
              nonUniqueUserVisitsActionsId: idForVisitAndyF, // Assuming this is the correct field to link with NonUniqueUserVisits
              action: passinaction,
            }
          }
        }); 
     
    
    
      } catch (error) {
        console.error(error);
      }
      }
  

  const goToChat = async () => {
      setIsOpen(false);
      setCurrentPath('/chat');
      setIsAllowedToSpeak(true);
      const idForVisit = sessionStorage.getItem('idForVisitAndyF');
      if (!idForVisit) {
      figureOutLocation("User opened chat");
      navigate('/chat');
      } else if (idForVisit) {
        
         const usermsg = "none";
         const aimsg = "none";
         const title = "User opened chat";

         const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
         const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
         const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;

          // Constructing the email header and body
          const emailHeader = `${title}`;
          const emailBody = `
          Location: ${fulllocation}
          User Message: ${usermsg}
          AI Message: ${aimsg}
          Origin: ${originatedfromfinal}
          `;

          const payload = {
              emailHeader: emailHeader,
              emailBody: emailBody
          };

          fetch(invokeUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
          await API.graphql({
              query: mutations.createActions,
              variables: {
                input: {
                  nonUniqueUserVisitsActionsId: idForVisit, 
                  action: "User opened chat",
                }
              }
            });

             
          navigate('/chat');
      }
  }

    // New useEffect for URL change detection
    useEffect(() => {
      if (urlCheckHasRun.current) return;

      const handleUrlChange = () => {
          const currentPath = window.location.pathname;
          if (currentPath === '/sol') {
              console.log('URL changed to /sol');
              goToSolEssay();
          } else if (currentPath === '/ai') {
              console.log('URL changed to /ai');
              goToAIEssay();
          }
      };

      // Rest of the new useEffect logic...
      handleUrlChange(); // Check immediately
      window.addEventListener('popstate', handleUrlChange);

      urlCheckHasRun.current = true;

      return () => {
          window.removeEventListener('popstate', handleUrlChange);
      };
  }, []);


  const goToAIEssay = async () => {
      setIsOpen(false);
      setCurrentPath('/ai');
      navigate('/ai');
      // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
      const idForVisit = sessionStorage.getItem('idForVisitAndyF');
      if (!idForVisit) {
      figureOutLocation("User viewed AI essay");
      } else if (idForVisit) {
        
        const usermsg = "none";
        const aimsg = "none";
        const title = "User viewed AI essay";

        const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
        const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
        const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};

 fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});
          await API.graphql({
              query: mutations.createActions,
              variables: {
                input: {
                  nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                  action: "User viewed AI essay",
                }
              }
            }); 
          
      }
  }

  const goToSolEssay = async () => {
      setIsOpen(false);
      setCurrentPath('/sol');
      navigate('/sol');
      // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
      const idForVisit = sessionStorage.getItem('idForVisitAndyF');
      if (!idForVisit) {
      figureOutLocation("User viewed SOL essay");
      //navigate('/sol'); disable navigation because it does go there automatically
      } else if (idForVisit) {

        
        const usermsg = "none";
        const aimsg = "none";
        const title = "User viewed SOL essay";

        const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
        const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
        const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};

fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});

          await API.graphql({
              query: mutations.createActions,
              variables: {
                input: {
                  nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                  action: "User viewed SOL essay",
                }
              }
            }); 
            
          
      }
  }


  const goToBio = async () => {
    setIsOpen(false);
    setCurrentPath('/');
    navigate('/');
    // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
    const idForVisit = sessionStorage.getItem('idForVisitAndyF');
    if (!idForVisit) {
    figureOutLocation("User viewed bio");
    
    } else if (idForVisit) {

      
      const usermsg = "none";
      const aimsg = "none";
      const title = "User viewed bio";

      const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
      const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
      const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};

 fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});

        await API.graphql({
            query: mutations.createActions,
            variables: {
              input: {
                nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                action: "User viewed bio",
              }
            }
          }); 
          
      
    }
}


  const close = async () => {
    setIsOpen(false);
  }

  const openTwitter = async () => {
    window.open("https://twitter.com/maxupside", "_blank");
    // add db entry

    setIsOpen(false);
    // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
    const idForVisit = sessionStorage.getItem('idForVisitAndyF');
    if (!idForVisit) {
    figureOutLocation("User viewed your twitter profile");
    } else if (idForVisit) {

      
      const usermsg = "none";
      const aimsg = "none";
      const title = "User viewed your twitter profile";

      const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
      const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
      const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};

 fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});

        await API.graphql({
            query: mutations.createActions,
            variables: {
              input: {
                nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                action: "User viewed your twitter profile",
              }
            }
          }); 
          
    }
}

  

  const openEmail = async () => {
    setIsOpen(false);
    window.open("mailto:andy@a1a2.ai", "_blank");    
  }

  const goToStartups = async () => {

    setIsOpen(false);
    setCurrentPath('/startups');
    navigate('/startups');
    // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
    const idForVisit = sessionStorage.getItem('idForVisitAndyF');
    if (!idForVisit) {
    figureOutLocation("User viewed your startups page");
    
    } else if (idForVisit) {

      
      const usermsg = "none";
      const aimsg = "none";
      const title = "User viewed your startups page";

      const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
      const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
      const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};
 
fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});

        await API.graphql({
            query: mutations.createActions,
            variables: {
              input: {
                nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                action: "User viewed your startups page",
              }
            }
          }); 
          
    }
    
}

/*   const goToWorkWithMe = async () => {
    setIsOpen(false);
    setCurrentPath('/workwithme');
    // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
    const idForVisit = sessionStorage.getItem('idForVisitAndyF');
    if (!idForVisit) {
    figureOutLocation("User viewed your work with me page");
    navigate('/workwithme');
    } else if (idForVisit) {

      
      const usermsg = "none";
      const aimsg = "none";
      const title = "User viewed your work with me page";

      const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
      const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
      const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};

fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});

        await API.graphql({
            query: mutations.createActions,
            variables: {
              input: {
                nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                action: "User viewed your work with me page",
              }
            }
          });
          
    navigate('/workwithme');
        }
}
 */

const goToPredictions = async () => {

    setIsOpen(false);
    setCurrentPath('/predictions');
    navigate('/predictions');
    // get idForVisit from sessionStorage if it exists, if it does not exist, then create one using similar logic as above
    const idForVisit = sessionStorage.getItem('idForVisitAndyF');
    if (!idForVisit) {
    figureOutLocation("User viewed your predictions page");
    
    } else if (idForVisit) {

      
      const usermsg = "none";
      const aimsg = "none";
      const title = "User viewed your predictions page";

      const userInfo = await API.graphql(graphqlOperation(queries.getNonUniqueUserVisits, { id: idForVisit }));
      const fulllocation = userInfo.data.getNonUniqueUserVisits.location;
      const originatedfromfinal = userInfo.data.getNonUniqueUserVisits.origin;


// Constructing the email header and body
const emailHeader = `${title}`;
const emailBody = `
Location: ${fulllocation}
User Message: ${usermsg}
AI Message: ${aimsg}
Origin: ${originatedfromfinal}
`;

const payload = {
    emailHeader: emailHeader,
    emailBody: emailBody
};

fetch(invokeUrl, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});

        await API.graphql({
            query: mutations.createActions,
            variables: {
              input: {
                nonUniqueUserVisitsActionsId: idForVisit, // Assuming this is the correct field to link with NonUniqueUserVisits
                action: "User viewed your predictions page",
              }
            }
          }); 
          
    }
  
}

return (
  <div className='sidebar'>

      <nav className="menu-options"> {/* Use <nav> to apply nav styles */}
        <a onClick={goToBio}>{currentPath === '/' && '>'} me</a>

        <a onClick={goToStartups}>{currentPath === '/startups' && '>'} startups</a>
        <a onClick={goToPredictions}>{(currentPath === '/predictions' || currentPath === '/sol' || currentPath === '/ai') && '>'} predictions</a>
        <a onClick={openTwitter}>twitter</a>
        <a onClick={openEmail}>email</a>
      </nav>


  </div>
);

}

export default Sidebar;


/*


        <a onClick={goToChat}>{currentPath === '/chat' && '>'} speak to an ai</a>


*/